* { box-sizing: border-box; }

body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Helvetica,sans-serif;
  line-height: 1.5;
  margin: 0;
  color: #111;
  background-color: #fff;
  background: #efefef;
}

a, a:visited, .link {
  color: #0074D9;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

a.btn-primary {
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary[disabled] {
  background: #0075d9;
}

.btn-secondary {
  color: #fff;
  background-color: #aaa;
  border-radius: 3px;
}

a.btn-secondary {
  color: #fff;
}

.btn-small {
  font-size: .75rem;
  padding: 0.25rem 0.5rem;
}

.background-light-gray {
  background: #ddd;
}

@media (min-width: 64em) {
  .lg-pr3 {
    padding-right: var(--space-3);
  }
}
